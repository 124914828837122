/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import Script from 'next/script';
import React from 'react';

const EverflowScript = () => {
	return (
		<>
			<Script
				id="everflow-1"
				type="text/javascript"
				src="https://www.pqanr7dftrk.com/scripts/sdk/everflow.js"
				async
				strategy="beforeInteractive"
			/>
			<Script id="everflow-2" type="text/javascript" async strategy="beforeInteractive">
				{`
                var siteUrl = 'pickleballbrackets.com';
                var advertiserId = '1';

                window.onload = function() {
                    var elems = document.body.getElementsByTagName("a");
                    if (!EF.getAdvertiserTransactionId(advertiserId)) {
                        EF.click({
                            offer_id: EF.urlParameter('oid'),
                            affiliate_id: EF.urlParameter('affid'),
                            sub1: EF.urlParameter('sub1'),
                            sub2: EF.urlParameter('sub2'),
                            sub3: EF.urlParameter('sub3'),
                            sub4: EF.urlParameter('sub4'),
                            sub5: EF.urlParameter('sub5'),
                            uid: EF.urlParameter('uid'),
                            source_id: EF.urlParameter('source_id'),
                            transaction_id: EF.urlParameter('_ef_transaction_id'),
                        }).then(function(transaction_id){
                            for (var i = 0; i < elems.length; i++){
                                if (elems[i].href.includes(siteUrl)) {
                                    var url = new URL(elems[i].href);
                                    url.searchParams.set('_ef_transaction_id', transaction_id);
                                    elems[i].href = url.href
                                }
                            }
                        });
                    } else {
                        for (var i = 0; i < elems.length; i++){
                            if (elems[i].href.includes(siteUrl)) {
                                var url = new URL(elems[i].href);
                                url.searchParams.set('_ef_transaction_id', EF.getAdvertiserTransactionId(advertiserId));
                                elems[i].href = url.href
                            }
                        }
                    }
                }
                `}
			</Script>
		</>
	);
};

export default EverflowScript;
