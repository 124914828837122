import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';

const RenderGoogleScripts = ({ ga_id }: { ga_id: string }) => (
	<>
		<GoogleTagManager gtmId={ga_id} />
		<GoogleAnalytics gaId={ga_id} />
	</>
);

export default RenderGoogleScripts;
