import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, NavbarItem } from '@pickleballinc/react-ui';
import { Menu } from '@pickleballinc/react-ui';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import logo from '@/assets/logo-pickleball.svg';
import ptLogo from '@/assets/pt-logo-icon-white.svg';
import { useAuth } from '@/contexts/auth/session';
import { BLOG_LABEL } from '@/utils/constants';

import GlobalSearch from './GlobalSearch';
import { Nav } from './Nav';
import { nav, tournamentsNav } from './nav.config';
import { UserProfile } from './UserProfile';

const validBlogPathname = /^\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$/;

export default function TopNavbar({ isTourneyPage }: { isTourneyPage: boolean }) {
	const { user } = useAuth();
	const router = useRouter();
	const [baseURL, setBaseURL] = useState('');

	useEffect(() => {
		setBaseURL(window.location.href);
	}, []);

	const pathname = router.pathname;
	const mainColor = isTourneyPage ? 'blue-600' : 'experiment';

	const [searchShown, setSearchShown] = useState(false);
	const [mobileMenuOpened, setMobileMenuOpened] = React.useState(false);
	const searchRef = useRef(null);

	const onRouteChangeStart = () => {
		setMobileMenuOpened(false);
	};

	useEffect(() => {
		router.events.on('routeChangeStart', onRouteChangeStart);

		return () => {
			router.events.off('routeChangeStart', onRouteChangeStart);
		};
	}, [router]);

	useEffect(() => {
		if (mobileMenuOpened) {
			document.documentElement.style.overflowY = 'hidden';
		} else {
			document.documentElement.style.removeProperty('overflow-y');
		}
	}, [mobileMenuOpened]);

	const { data } = useQuery(
		['search-buttons'],
		async () => {
			const response = await axios.get(`/api/v1/global-search-buttons`);
			return response.data?.data;
		},
		{ initialData: { payload: [] } }
	);

	const { data: newsCategoriesData } = useQuery(
		['news-categories'],
		async () => {
			const response = await axios.get(`/api/v2/news-categories`);
			return response.data?.data;
		},
		{ initialData: [] }
	);

	const isTournaments =
		isTourneyPage ||
		(typeof window !== 'undefined' &&
			(window.location.hostname.includes('pickleballtournaments.com') || window.location.hostname.includes('pickleballtournamentsdev.net')));

	const mainNav = isTournaments ? tournamentsNav : nav;

	const pickleballHref = isTournaments ? `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}` : '';

	const filteredCategories = newsCategoriesData ? newsCategoriesData.filter((category: any) => category.slug !== 'news') : [];

	const pathSegments = pathname?.split('/').filter((segment) => segment) || [];
	const categorySlug = pathSegments[0];
	const isBlogPathname = pathname && validBlogPathname.test(pathname) && filteredCategories.some((category: any) => category.slug === categorySlug);

	return (
		<header className="relative z-50" id="pb-mainheader">
			<div className="relative overflow-hidden">
				<Navbar className="sticky top-0 z-50" as="nav" role="navigation">
					<Navbar.Brand tag={Link} href="/" className={classNames(isTourneyPage ? '!bg-blue-600 before:!bg-blue-600' : '')}>
						{isTourneyPage ? (
							<Image src={ptLogo} alt="Pickleball Tournaments" priority width={36} height={36} className="skew-x-12" />
						) : (
							<Image src={logo} alt="Pickleball" priority width={32} height={32} className="skew-x-12" />
						)}
					</Navbar.Brand>
					<div className="mr-6" />

					<Nav isTournaments={isTournaments} />

					<div className="ml-auto mr-1 flex">
						{user?.data.uuid ? (
							<NavbarItem className="!inline-flex sm:!hidden">
								<UserProfile user={user} />
							</NavbarItem>
						) : (
							<NavbarItem className="!inline-flex sm:!hidden">
								<Link
									href={`${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/v3/signin?flow=SelfService&continue=${encodeURIComponent(baseURL)}`}
									rel="nofollow"
								>
									Login
								</Link>
							</NavbarItem>
						)}

						<div
							className={classNames(
								'!hidden -skew-x-12 items-center justify-center px-7 text-white sm:!inline-flex',
								'bg-' + mainColor
							)}
							tabIndex={0}
							role="button"
						>
							<div className="skew-x-12">
								<Menu showArrow={false} placement="bottom" offset={24} width={172}>
									<Menu.Trigger asChild>
										<div className="flex cursor-pointer items-center gap-1.5">
											<span className="relative flex text-sm font-semibold text-white transition-all duration-150 hover:text-opacity-80">
												{BLOG_LABEL}
											</span>
											<FontAwesomeIcon icon={faChevronDown} size="sm" />
										</div>
										<span className="sr-only" aria-label="Dropdown open" />
									</Menu.Trigger>
									<Menu.List className="min-w-[172px] !max-w-[172px] !px-0 !py-2">
										{filteredCategories.map((category: any) => {
											return (
												<Menu.Item
													key={category.uuid}
													className="cursor-pointer rounded-lg bg-white text-sm font-medium text-gray-900 hover:bg-[#EBE9FE]"
												>
													<Link
														href={`${pickleballHref}/${category.slug}/all`}
														className={`line-clamp-1 w-full text-ellipsis px-4 py-2 !outline-none ${isBlogPathname && categorySlug === category.slug ? 'underline' : ''}`}
													>
														{category.title}
													</Link>
												</Menu.Item>
											);
										})}
									</Menu.List>
								</Menu>
							</div>
						</div>

						<NavbarItem
							className={classNames(
								isTourneyPage && !searchShown ? '!border-blue-600' : '',
								isTourneyPage && searchShown ? '!border-blue-600 !bg-blue-600' : ''
							)}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setSearchShown(!searchShown);
							}}
							active={searchShown}
							ref={searchRef}
						>
							<FontAwesomeIcon className="sm:!hidden" icon={faSearch} aria-label="Search" title="Search Icon" role="button" />
							<FontAwesomeIcon
								className="!hidden sm:!flex"
								size="lg"
								icon={faSearch}
								aria-label="Search"
								title="Search Icon"
								role="button"
							/>
						</NavbarItem>
						<NavbarItem
							className={classNames(
								'!inline-flex items-center sm:!hidden',
								isTourneyPage && !mobileMenuOpened ? '!border-blue-600' : '',
								isTourneyPage && mobileMenuOpened ? '!border-blue-600 !bg-bluey-600 before:!bg-blue-600' : ''
							)}
							onClick={() => setMobileMenuOpened(!mobileMenuOpened)}
						>
							<FontAwesomeIcon icon={faBars} style={{ width: 12 }} />
						</NavbarItem>
					</div>

					{mobileMenuOpened && (
						<div className="fixed inset-0 z-50 flex w-full overflow-hidden sm:hidden">
							<div
								aria-hidden="true"
								className="absolute inset-0 bg-white/30 backdrop-blur-md"
								onClick={() => setMobileMenuOpened(false)}
							/>
							<div
								aria-hidden="true"
								className="absolute right-2 top-2 flex size-11 items-center justify-center rounded-md bg-brand-700"
								onClick={() => setMobileMenuOpened(false)}
							>
								<FontAwesomeIcon icon={faTimes} className="text-white" />
							</div>
							<div className="relative z-50 w-[46%] shrink-0 overflow-auto bg-gray-800">
								<ul className="flex flex-col divide-y divide-gray-900">
									{mainNav.map((item) => {
										return (
											<React.Fragment key={item.id}>
												{item.children === BLOG_LABEL && filteredCategories ? (
													<>
														{filteredCategories.map((category: any) => (
															<li key={category.uuid} className="list-none">
																<Link
																	href={`${pickleballHref}/${category.slug}/all`}
																	className="block px-6 py-4 text-sm font-bold leading-5 text-white"
																>
																	{category.title}
																</Link>
															</li>
														))}
													</>
												) : (
													<li className="list-none">
														<Link href={item.href} className="block px-6 py-4 text-sm font-bold leading-5 text-white">
															{item.children}
														</Link>
													</li>
												)}
											</React.Fragment>
										);
									})}
								</ul>
								{isTourneyPage && (
									<ul className="flex flex-col divide-y divide-gray-900">
										<li className="list-none">
											<Link href={pickleballHref || '/'} className="block px-6 py-4 text-sm font-bold leading-5 text-white">
												Pickleball.com
											</Link>
										</li>
									</ul>
								)}
							</div>
						</div>
					)}
				</Navbar>
			</div>
			{searchShown && (
				<GlobalSearch
					mainColor={mainColor}
					searchVisible={searchShown}
					globalSearchButtons={data || { payload: [] }}
					toggleRef={searchRef}
					onHide={() => setSearchShown(false)}
				/>
			)}
		</header>
	);
}
