import { RolePermissionMapper } from 'protos/model';
import { ReadUser } from 'protos/service';
import React, { createContext, useContext, useMemo, useReducer } from 'react';

export interface UserSession {
	session: {
		email: string;
		uuid: string;
		token: string;
		expiration: string;
		isCompleted: boolean;
		isSuperAdmin: boolean;
		oltToken: string;
		pbUuid: string;
		roles: RolePermissionMapper[];
	};
	data: ReadUser;
}

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useAuth0` hook.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface AuthState {
	error?: Error;
	isAuthenticated: boolean;
	isLoading: boolean;
	user?: UserSession;
}

/**
 * The initial auth state.
 */
export const initialAuthState: AuthState = {
	isAuthenticated: false,
	isLoading: true
};

export interface AuthContextInterface extends AuthState {}

export const initialContext = {
	...initialAuthState
};

/**
 * The Auth Context
 */
const AuthContext = createContext<AuthContextInterface>(initialContext);

export const useAuth = (context = AuthContext): AuthContextInterface => useContext(context) as AuthContextInterface;

export interface AuthProviderOptions {
	/**
	 * The child nodes your Provider has wrapped
	 */
	children?: React.ReactNode;

	/**
	 * Context to use (if not provided default is used)
	 */
	context?: React.Context<AuthContextInterface>;

	/**
	 * Initial session which could be provided by pages (to avoid flash of unauthenticated content)
	 * Otherwise auth check would be done client side
	 */
	session: UserSession;
}

// TODO: At later stage we will use custom authentication flow
type Action = { type: 'NOT_IMPLEMENTED' };

/**
 * Handles how that state changes in the `useAuth0` hook.
 */
export const reducer = (state: AuthState, action: Action): AuthState => {
	switch (action.type) {
		default:
			return state;
	}
};

export const AuthProvider = (opts: AuthProviderOptions): JSX.Element => {
	const { children, context = AuthContext, session } = opts;

	// TODO: We might use dispatch later for custom authentication mechanism
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [state, _dispatch] = useReducer(reducer, {
		...initialAuthState,
		isAuthenticated: !!session && !!session.session && !!session.data,
		user: session
	});

	const contextValue = useMemo(() => {
		return {
			...state
		};
	}, [state]);

	return <context.Provider value={contextValue}>{children}</context.Provider>;
};
