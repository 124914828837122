import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const stub = (): never => {
	throw new Error('You forgot to wrap your component in <Loader>.');
};

interface ILoaderContext {
	isLoading: boolean;
	setLoading: (loading: boolean) => void;
}

const initialContext: ILoaderContext = {
	isLoading: false,
	setLoading: stub
};

const loaderContext = createContext<ILoaderContext>(initialContext);

export const useFullPageLoader = (context = loaderContext): ILoaderContext => useContext(context) as ILoaderContext;

interface LoaderProviderProps {
	children: React.ReactNode | React.ReactNode[];
}

export const LoaderProvider = ({ children }: LoaderProviderProps) => {
	const [isLoading, setIsLoading] = useState(false);

	const setLoading = useCallback((loading: boolean) => {
		setIsLoading(loading);
	}, []);

	useEffect(() => {
		if (isLoading) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.removeAttribute('style');
		}
	}, [isLoading]);

	const contextValues = useMemo(() => {
		return {
			isLoading,
			setLoading
		};
	}, [isLoading, setLoading]);

	return (
		<loaderContext.Provider value={contextValues}>
			{isLoading && (
				<div className="fixed inset-0 z-[10999] flex items-center justify-center bg-white">
					<FontAwesomeIcon className="text-darkblue-500" icon={faSpinner} spin style={{ fontSize: 110 }} />
				</div>
			)}
			{children}
		</loaderContext.Provider>
	);
};
