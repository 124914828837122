import EverflowScript from '@/components/RenderScripts/EverflowScript';
import RenderGoogleScripts from '@/components/RenderScripts/RenderGoogleScripts';

interface RenderScriptsProps {
	ga_id: string;
	isTourneyPage?: boolean;
}

const RenderScripts = ({ ga_id }: RenderScriptsProps) => (
	<>
		<RenderGoogleScripts ga_id={ga_id} />
		<EverflowScript />
	</>
);

export default RenderScripts;
